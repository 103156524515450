@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Nunito", sans-serif;
}